import React from 'react'
import './index.css'







const Table = () => {
    return ( 
        <div className='md:mx-20 my-6 overflow-x-auto '>
           



<html>

<body>



<table>




<tr>

<th colSpan='9'>CIVIL DIMENSIONS FOR JHANSI HOMEY PLUS ELEVATOR</th>

</tr>

  <tr style={{backgroundColor:'#b4d6d9'}}>
    <td colSpan={2}>CAPACITY	</td>
    <td colSpan={2}>CAR INSIDE	</td>
    <td colSpan={2}>LIFT WELL	</td>   
    <td>ENTRANCE</td>

 
  </tr>


  <tr>
   
    <td>PERSONS</td>
    <td>LOAD/KGS</td>
    <td>WIDTH</td>
    <td>DEPTH</td>
    <td>WIDTH</td>
    <td>DEPTH</td>
    <td></td>
    
  

  </tr>

  <tr>
   
   <td>6</td>
   <td>408</td>
   <td>900</td>
   <td>1100</td>
   <td>1500</td>
   <td>1475</td>
   <td>700</td>
   
 

 </tr>


 <tr>
   
   <td></td>
   <td></td>
   <td>1000</td>
   <td>1100</td>
   <td>1575</td>
   <td>1475</td>
   <td>800</td>
   
 

 </tr>



 <tr>
   
   <td>8</td>
   <td>544</td>
   <td>1050</td>
   <td>1350</td>
   <td>1650</td>
   <td>1600</td>
   <td>800</td>
   
 

 </tr>

 <tr>
   
   <td></td>
   <td></td>
   <td>1300</td>
   <td>1100</td>
   <td>1885</td>
   <td>1500</td>
   <td>800</td>
   
 

 </tr>

 <tr>
   
   <td>10</td>
   <td>680</td>
   <td>1100</td>
   <td>1600</td>
   <td>1775</td>
   <td>1925</td>
   <td>800</td>
   
 

 </tr>

 <tr>
   
   <td></td>
   <td></td>
   <td>1200</td>
   <td>1450</td>
   <td>1875</td>
   <td>1775</td>
   <td>800</td>
   
 

 </tr>
 

</table>

</body>
</html>








        </div>

       





        
    )
}

export default Table