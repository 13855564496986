import React from 'react'

const aboutone = () => {
  return (
    <section className=" overflow-hidden py-6 px-4  bg-white
     sm:px-8" style={{ fontFamily: 'Poppins', fontWeight: '400' }}>
    <div className="w-full h-full rounded-full   blur-2xl opacity-10"></div>
    <div className=" text-start mx-auto ">
    <div className="bg-gray-100 py-4 px-4 sm:py-2 sm:px-2">
    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-5">
    <h1 className="sm:text-[26px] text-center  text-2xl font-bold title-font mb-3 text-[#060A4C] " style={{ fontFamily: 'Poppins', fontWeight: '400' }}>
      JHANSI ELEVATOR
    </h1>
    <p className="text-[#060A4C] mt-1 text-center text-sm sm:text-[16px] leading-relaxed" style={{ fontFamily: 'Poppins', fontWeight: '400' }}>
      JHANSI maintains and modernizes a wide range of elevator products. Our services include the removal and replacement of old elevator equipment or the refurbishment of existing equipment to improve performance and meet the latest lift standards. We provide after-sale services to our customers and believe in constantly working hard to achieve the benchmark for quality in our products and services. We value your relationship with us and assure you of our best services at all times.
    </p>
    </div>
    </div>
      
    </div>
</section>
  )
}

export default aboutone