import React from 'react'

import about from '../../Images/models/electra.webp'


const Banner = () => {
    return ( 
        <div class="container px-5 py-10 mx-auto" bis_skin_checked="1">
    <div class="lg:w-4/5 mx-auto flex flex-wrap" bis_skin_checked="1">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0" bis_skin_checked="1">
        
        <h1 class="text-[#060A4C] text-3xl text-start title-font font-medium mb-4">3. Electra  </h1>
       
        <p class="leading-relaxed text-justify mb-4">these are the main means of transportation elevators installed in appraments. offices. hospitals .hotels. 
these are categorised into various types depending on the load carring capacity and the rated speed. this model is mrl (machine room less)  elevator installed on low rise buildings 

</p>
       
      
      
      </div>
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-96 h-full object-cover object-center rounded" loading="lazy" src={about}/>
    </div>
  
  

        
        </div>











       
       





        
    )
}

export default Banner