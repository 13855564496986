import React from 'react'
import './index.css'







const Table = () => {
    return ( 
        <div className='md:mx-20 my-6 overflow-x-auto '>
           



<html>

<body>



<table>




<tr>

<th colSpan='9'>CIVIL DIMENSIONS FOR JHANSI VIRAT XTRA LARGE ELEVATOR</th>

</tr>

  <tr style={{backgroundColor:'#b4d6d9'}}>
    <td >CAPACITY	</td>
    <td colSpan={2}>CAR INSIDE	</td>
    <td colSpan={2}>LIFT WELL	</td>   
    <td colSpan={2}>ENTRANCE</td>

 
  </tr>


  <tr>
   
  
    <td>LOAD/KGS</td>
    <td>WIDTH</td>
    <td>DEPTH</td>
    <td>WIDTH</td>
    <td>DEPTH</td>
    <td>WIDTH</td>
    <td>HEIGHT</td>
    
  

  </tr>

  <tr>
   
   
   <td>500</td>
   <td>1100</td>
   <td>1200</td>
   <td>1800</td>
   <td>1550</td>
   <td>1100</td>
   <td>2000</td>
   
 

 </tr>





  <tr>
   
  
   <td>800</td>
   <td>1100</td>
   <td>1800</td>
   <td>1800</td>
   <td>2150</td>
   <td>1100</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>1000</td>
   <td>1400</td>
   <td>1800</td>
   <td>2200</td>
   <td>2200</td>
   <td>1400</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>1500</td>
   <td>1700</td>
   <td>2000</td>
   <td>2500</td>
   <td>2400</td>
   <td>1700</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>2000</td>
   <td>1700</td>
   <td>2500</td>
   <td>2500</td>
   <td>2900</td>
   <td>1700</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>2500</td>
   <td>2000</td>
   <td>2500</td>
   <td>2900</td>
   <td>2900</td>
   <td>2000</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>3000</td>
   <td>2000</td>
   <td>3000</td>
   <td>2900</td>
   <td>3400</td>
   <td>2000</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>4000</td>
   <td>2500</td>
   <td>3000</td>
   <td>3400</td>
   <td>3400</td>
   <td>2500</td>
   <td>2000</td>
   
 

 </tr>


 <tr>
   
   
   <td>5000</td>
   <td>2500</td>
   <td>3600</td>
   <td>3400</td>
   <td>3950</td>
   <td>2500</td>
   <td>2000</td>
   
 

 </tr>

 

</table>

</body>
</html>








        </div>

       





        
    )
}

export default Table